@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;


// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}
